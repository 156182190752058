export default {
  data() {
    return {
      currentYear: ""
    };
  },
  mounted() {
    this.getCurrentYear();
  },
  methods: {
    getCurrentYear() {
      this.currentYear = new Date().getFullYear();
    }
  }
};
